(function($){

	var heroSlider = function() {
		$('.hero__slider-has-slides').slick({
			dots: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 8000,
		});
	};

	$(document).ready(function(){
		heroSlider();
	});

})(jQuery);